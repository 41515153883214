import React from 'react';
// import Button from '@mui/material/Button';
import './App.css'
import GridCanvas from './newCanvas';
function App() {
  return (
    <div style={{ padding: 0 }}>
      <GridCanvas/>
    </div>
  );
}

export default App;